import React, { Component, useEffect, useState } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Error404 from "./404"
import productImage from '../statics/images/assets/product-image.jpg'
import PrimaryButton from "../components/primary-button.jsx";
import SpecialProducts from "../components/special-products.jsx"
import Breadcrump from "../components/breadcrump";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import axios from "axios"

const OnDemand = (props) => {

    let { id } = useParams();
    const [post, setPost] = useState(null);
    const navigate = useNavigate()
    const previews_links = [
        { route: "/", label: "Home" },
        { route: "/products", label: "Products" },
        { route: "", label: "Starlight" },
    ]
    useEffect(() => {

    })





    return (
        <div className="pt-5">
            <Container className="py-4">
                <Breadcrump previews_links={previews_links} />
                <div className="px-0 w-75 py-5">
                    <h2 className="part-title pb-0 text-dark">On demand chemistry.</h2>
                    <h3 className="part-title text-grey pt-0 ">We do not only tailored solutions: we <strong>understand your biological problematics</strong> and <strong>create adequate chemical biology tools</strong> in response.</h3>
                </div>
            </Container>
            <div className="bg-wepredic-light position-relative" style={{ zIndex: 0 }}>
                <div className="h-100 w-25 position-absolute bg-wepredic-dark">d</div>
                <Container className="bg-wepredic-dark position-relative h-100 p-0" style={{ zIndex: 0 }}>
                    <div className="position-absolute h-100 w-100 d-flex flex-row" style={{ zIndex: -1, left: 0 }}>
                        <div className="h-100 border-start border-light" style={{ width: "15%" }}></div>
                        <div className="h-100 border-start border-light" style={{ width: "15%" }}></div>
                        <div className="h-100 border-start border-light" style={{ width: "20%" }}></div>
                        <div className="h-100 border-start border-light" style={{ width: "30%" }}></div>
                        <div className="h-100 border-start border-light bg-wepredic-light" style={{ width: "20%" }}></div>
                    </div>
                    <div className="pt-4"></div>
                    <h3 className="part-title text-light py-5 w-25">Chronology</h3>
                    <div className="w-100 py-5 d-flex flex-row text-white" style={{ zIndex: 1 }}>
                        <div className="d-flex flex-row" style={{ width: "15%" }}>
                            <div >
                                <div className="pin">1</div>
                            </div>
                            <div className="ps-2">
                                <div className="underline">Contact us</div>
                                <div className="small">and describe your problematics</div>
                            </div>

                        </div>
                        <div className="d-flex flex-row" style={{ width: "15%" }}>
                            <div >
                                <div className="pin">2</div>
                            </div>
                            <div className="ps-2">
                                <div className="">We met and discuss</div>
                                <div className="small">your problematic</div>
                            </div>
                        </div>
                        <div className="d-flex flex-row" style={{ width: "20%" }}>
                            <div >
                                <div className="pin">3</div>
                            </div>
                            <div className="ps-2">
                                <div className="">We send you</div>
                                <div className="small">a proposal solution</div>
                            </div>

                        </div>
                        <div className="d-flex flex-row" style={{ width: "30%" }}>
                            <div >
                                <div className="pin">4</div>
                            </div>
                            <div className="ps-2">
                                <div className="">You say "Go for it"</div>
                            </div>

                        </div>
                        <div className="d-flex flex-row" style={{ width: "20%" }}>
                            <div >
                                <div className="pin">5</div>
                            </div>
                            <div className="ps-2">
                                <div className="text-wepredic">We create your solution</div>
                                <div className="small text-wepredic-dark">and describe your problematics</div>
                            </div>

                        </div>
                    </div>
                    <div className="py-5" style={{ width: "80%", zIndex: 4 }}>
                        <div className="w-100 text-center text-white small">2 weeks time</div>
                        <div className="d-flex flex-row w-100 arrow">
                            <div className="pin bg-white"></div>
                            <div className="w-100 thick"></div>
                            <FontAwesomeIcon className="text-white" style={{margin: "2px -1"}} icon={faArrowRight} />
                        </div>
                    </div>
                    <div className="pb-5"></div>

                </Container>


            </div>

            <Container className="py-5">
                <Row>
                    <Col className="py-4 pe-0 pe-md-5" md={6}>
                        <p>Phrase introductive. Lister les avantages pour le potentiel client à contacter Wepredic pour le service on-demand chemistry.</p>
                        <ul>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla dapibus dui turpis, vitae posuere diam sagittis in.</li>
                            <li>Aenean vulputate sagittis nibh nec maximus. Vivamus tincidunt augue sit amet nunc tincidunt, sed sodales dolor consequat. </li>
                            <li>Fusce ornare leo nec lectus fermentum accumsan. Aliquam in odio a purus aliquet placerat. Quisque consequat at nibh ac euismod. </li>
                        </ul>
                    </Col>
                    <Col className="my-auto text-center">
                        <PrimaryButton content="i want on demand chemistry --->" theme="1" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OnDemand