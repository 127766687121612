import { useState, useEffect, useRef } from 'react';
import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import nuxe from "../statics/images/partners/nuxe.png"
import sephora from "../statics/images/partners/sephora.png"
import pierreFabre from "../statics/images/partners/pierre-fabre.png"
import yvesRocher from "../statics/images/partners/yves-rocher.png"

export default function SimpleSlider() {


    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      variableWidth: true,
      lazyLoad: true,
      nextArrow: <></>,
      prevArrow: <></>
    };

    return (
        <div className="py-3 partners">
            <div className="py-3" style={{ textAlign: "end", fontSize: "20px" }}>
                <FontAwesomeIcon className="pe-4" onClick={previous} icon={faArrowLeftLong} />
                <FontAwesomeIcon onClick={next} icon={faArrowRightLong} />
            </div>
            <Slider ref={slider => {sliderRef = slider}} 
                {...settings}>
                
                <div className="carousel-item">
                    <LazyLoadImage className="carousel-item-img my-auto" src={nuxe} />
                </div>
                <div className="carousel-item ">
                    <LazyLoadImage className="carousel-item-img" src={sephora} />
                </div>
                <div className="carousel-item ">
                    <LazyLoadImage className="carousel-item-img" src={pierreFabre} />
                </div>
                <div className="carousel-item ">
                    <LazyLoadImage className="carousel-item-img" src={yvesRocher} />
                </div>
                        
                    
            </Slider>
        </div>
      
    );
  }