import React, { Component, useEffect, useRef, useState } from "react"

import { Container, Col, Row, Label, Input, Badge, Button, FormGroup } from 'reactstrap'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom'

import axios from "axios"
import Breadcrump from "../components/breadcrump";

const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

function convertToPlain(html) {

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}


const News = (props) => {

    const navigate = useNavigate()


    const dataformat= (date) => {
        const d = new Date(date)
        return months[d.getMonth()].substring(0, 3) +" "+d.getDay()+", "+d.getFullYear()
    }

    const gotToNewPage = (route) => {
        navigate(route);
    }

    const previews_links = [
        {route: "/", label: "Home" },
        {route: "", label: "News" },
    ]

    return (
        <div className="pt-5">
            <Container className="py-4">
                <Breadcrump previews_links={previews_links} />
                <h3 className="pt-5 part-title">News. <span className="text-grey">Our latest and we believe interesting news.</span></h3>
                <div className="d-flex flex-row mt-5">
                    <div className="small text-uppercase my-auto h-100 text-muted">Company</div>
                    <div className="ps-4">
                        <Input type="checkbox" />
                        <Label className="my-auto small">Wepredic (40)</Label>
                    </div>
                    <div className="ps-4">
                        <Input type="checkbox" />
                        <Label className="my-auto small">Biopredic (10)</Label>
                    </div>
                    <div className="ps-4">
                        <Input type="checkbox" />
                        <Label className="my-auto small">Starlight (40)</Label>
                    </div>
                    <div className="ps-4">
                        <Input type="checkbox" />
                        <Label className="my-auto small">Eurosafe (20)</Label>
                    </div>
                    <div className="ps-4">
                        <Input type="checkbox" />
                        <Label className="my-auto small">Other (14)</Label>
                    </div>


                </div>
                <Row className="gx-5">
                    {
                        props.articles.map((article) =>
                            <Col md={4} className="py-4">
                                <div className="article-card h-100 d-flex flex-column justify-content-between">
                                    <div>
                                        <Badge className="my-badge text-uppercase bg-eurosafe " color="" pill>Chemical product</Badge>
                                        <div className="pt-5">
                                            <p className="pb-0 mb-0 small text-uppercase text-muted">{dataformat(article.date)}</p>
                                            {article.title.rendered}
                                            <p className="pt-5">{convertToPlain(article.excerpt.rendered)}</p>
                                        </div>
                                    </div>
                                    <Link to={"/news/"+article.id.toString()} className="text-eurosafe text-uppercase underline-0 small pt-5">{"read more"}&ensp;{"--->"}</Link>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </Container>
        </div>

    )
}

export default News