import React, { Component, useEffect, useState } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Error404 from "./404"
import productImage from '../statics/images/assets/product-image.jpg'
import PrimaryButton from "../components/primary-button.jsx";
import SpecialProducts from "../components/special-products.jsx"
import Breadcrump from "../components/breadcrump";
import CartClass from "../classes/cart"
import withRouter from '../components/with-router';
import Cookies from 'universal-cookie'

import axios from "axios"



const Cart = (props) => {


    const navigate = useNavigate()
    const gotToNewPage = (route) => {
        navigate(route);
    }

    return (
        <div className="pt-5">

            <Container className="py-4">
                <Row className="pt-5 gx-5">
                    <Col md={9}>
                        <div className="bg-light p-3">
                            <h4 className="mb-1 small"><b>Besoin d'un conseil ou d'un devis ?</b></h4>
                            <p className="mb-0">Nous sommes à votre disposition, vous pouvez nous contacter ou demander un devis.</p>
                        </div>
                        <div className="d-flex flew-row justify-content-between pt-4">
                            <h4 className="">Review your bag.</h4>
                            <p onClick={() => props.resetCart()} className="my-auto pointer">Clear bag</p>
                        </div>

                        {
                            props.cart.items.map((product) => {
                                return (
                                    <Row className="pt-3">
                                        <Col md={2} className="px-3">
                                            <div className="img-wrap h-100 w-100 bg-grey">
                                                <LazyLoadImage src={product.img} />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <p className="my-0 py-0 text-wepredic-dark"><b>{product.name}</b></p>
                                            <p className="my-0 py-0"><span className="text-grey">Format</span> Powdered product 1mg/mL{product.name}</p>
                                            <div className="d-flex flex-row pt-2">
                                                Quantity
                                                <PrimaryButton action={() => props.minusQuantity(product)} customClasses="radius-small px-2 py-0 m-0 mx-2" content="-" theme="4" />
                                                <div className="text-wepredic">{product.quantity}</div>
                                                <PrimaryButton action={() => props.addToCart(product, 1)} customClasses="radius-small px-2 py-0 m-0 mx-2 big-letter" content="+" theme="1" />
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <p className="my-0 py-0 text-end">{product.price * product.quantity} € TTC</p>
                                            <p className="my-0 py-0 small text-end">HT</p>
                                        </Col>
                                        <Col md={2}>
                                            <p onClick={()=>props.removeItemFromCart(product)} className="text-end small py-0 my-0 pointer">Remove</p>
                                        </Col>
                                    </Row>
                                )
                            })
                        }


                    </Col>
                    <Col md={3}>
                        <h3 className="pb-3">Summary</h3>
                        <div className="d-flex flex-row justify-content-between">
                            <p className="small text-muted">Items total HT</p>
                            <p className="small text-muted">130,50 €</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <p className="small text-muted">Shipping cost</p>
                            <p className="small text-muted">7,50 €</p>
                        </div>

                        <hr className="mt-1 mb-3" />
                        <div className="d-flex flex-row justify-content-between">
                            <p className="small text-muted">Total HT</p>
                            <p className="small text-muted">130,50 €</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <p className="small text-muted">Taxes</p>
                            <p className="small text-muted">13,50 €</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <p className="small text-muted">Total TTC</p>
                            <p className="small">{props.cart.total} €</p>
                        </div>
                        <hr className="mt-1 mb-0" />

                        <PrimaryButton action={()=> gotToNewPage("/order")} customClasses="radius-small py-2 w-100" theme="1" content="Request a quote" />
                    </Col>
                </Row>

            </Container>
        </div>
    )


}

export default Cart