import React, { Component, useEffect, useState } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom'
import Error404 from "./404"
import Breadcrump from "../components/breadcrump";

import background from '../statics/images/assets/news.jpg'
import axios from "axios"
import { render } from "@testing-library/react";
import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";
import withRouter from '../components/with-router';


class Post extends Component {

    constructor(props) {
        super(props);

        this.state = {
            post: null,
            height: 0
        };
        this.getArticle = this.getArticle.bind(this)
    }

    async getArticle() {
        const url = this.props.config.apiUrl+'article/'
        try {
            const response = await axios.get(url + this.props.params.id)
            this.setState({
                post: response.data,
            })
            document.body.style.overflow = "scroll";
        } catch (error) {
            console.log(error)
        }
    }



    componentDidMount() {
        
        this.getArticle().then(() => {
            this.props.setLoaded(true)
        })

        this.setState({
            height: window.innerHeight
        })
    }

    componentDidUpdate() {
        if (window.innerHeight !== this.state.height) {
            this.setState({
                height: window.innerHeight
            })
        }
    }


    render() {

        if(this.state.post === null && this.props.isLoaded){
            this.props.setLoaded(false)
        }

        if (this.props.isLoaded && this.state.post === undefined) {
            return <Error404 />
        }
        const previews_links = [
            { route: "/", label: "Home" },
            { route: "/news", label: "News" },
        ]

        return (
            <div className="">


                {this.state.post !== null ?
                    <>
                        <div className="window news-bg" style={{ objectFit: "cover", height: this.props.isSticky ? this.state.height : (this.state.height - 60), backgroundImage: `url(${this.state.post._embedded['wp:featuredmedia'] ? this.state.post._embedded['wp:featuredmedia'][0]['source_url'] : background})` }}>

                            <div className="pt-5 news-bg-after">
                                <Container className="py-4 d-flex flex-column justify-content-between h-100">
                                    <Breadcrump previews_links={previews_links} />
                                    <h1 className="text-center text-white py-3 w-75 mx-auto">{this.state.post.title.rendered}</h1>
                                    <div className="pt-5"></div>
                                </Container>
                            </div>

                        </div>
                        <Container className="post eurosafe py-5">

                            <p dangerouslySetInnerHTML={{ __html: this.state.post.content.rendered }} />
                        </Container>

                    </>
                    : <></>
                }
            </div>
        )
    }



}

export default withRouter(Post)