import React, { Component, useEffect, useRef, useState } from "react"

import { Container, Col, Row, Label, Input, Badge, Button } from 'reactstrap'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom'

import productImage from '../statics/images/assets/product-image.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Breadcrump from "../components/breadcrump";



const Products = (props) => {

    const stickyFilter = useRef(null);
    const [height, setHeight] = useState(100);
    const [padding, setPadding] = useState(0)
    const [search, setSearch] = useState("")
    const [products, setProducts] = useState([])
    const navigate = useNavigate()

    const [filter, setFitler] = useState()

    const previews_links = [
        {route: "/", label: "Home" },
        {route: "", label: "Products" },
    ]
    useEffect(() => {

        if (filter != "Starlight" && props.store.length > 0) {
            setProducts(props.store.filter((product) =>
                product.categories.some((categorie) => categorie.name == "Starlight"))
            )
            setFitler("Starlight")
        }
        const handleScroll = () => {
            if (window.scrollY > 130) {
                props.editStickyFilter(true)
                setPadding(80)
            } else {
                props.editStickyFilter(false)
            }

        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    })


    const gotToNewPage=(route)=>{
        navigate(route);
      }

    return (
        <div className="pt-5">
            <Container className="py-4">
                <Breadcrump previews_links={previews_links} />

                <h3 className="part-title pt-3">ADMET Products</h3>
                <Row className="my-5 " >
                    <Col md={3} className="position-relative d-none d-xl-block " style={{ height: height, }}>
                        <div ref={stickyFilter} className="side-filter" style={props.isStickyFilter ? { position: "fixed", top: padding, zIndex: -1 } : { position: "absolute" }}>
                            <Row className="p-0 m-0 pb-3 w-100 border-bottom">

                                <Col xl={8} className="p-0 m-0">
                                    <div className="input-box">
                                        {search.length == 0 ? <FontAwesomeIcon className="form-icon" icon={faSearch} /> : <></>}
                                        <Input className="my-input" onChange={e => setSearch(e.target.value)} placeholder="     Keyword or CAS" />
                                    </div>
                                </Col>
                                <Col xl={4} className="p-0 m-0">
                                    <Button className="bg-eurosafe border-0">Search</Button>
                                </Col>


                            </Row>
                            <div className="border-bottom pt-2 pb-3 w-100">
                                <p className="text-uppercase title">Product type</p>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        Biological products (26)
                                    </Label>
                                </div>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        Service (11)
                                    </Label>
                                </div>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        Chemical products (26)
                                    </Label>
                                </div>
                            </div>
                            <div className="border-bottom pt-2 pb-3">
                                <p className="text-uppercase title">Product type</p>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        ADMET Products (26)
                                    </Label>
                                </div>

                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        ADMET Libraries (26)
                                    </Label>
                                </div>
                            </div>
                            <div className="py-2">
                                <p className="text-uppercase title">Segments</p>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        CYP 450 Activity (12)
                                    </Label>
                                </div>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        Transporter Act (9)
                                    </Label>
                                </div>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        Phase II Activity (15)
                                    </Label>
                                </div>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        Phase I Activity (22)
                                    </Label>
                                </div>
                                <div className="py-1">
                                    <Input type="checkbox" />
                                    {' '}
                                    <Label check className="label">
                                        Genotoxic (18)
                                    </Label>
                                </div>

                            </div>
                        </div>


                    </Col>
                    <Col md={9} className="" >
                        <div style={{ minHeight: "500px" }}>
                            <div className="d-flex w-100 justify-content-end">
                                <div className="p-2">{products.length.toString()} results</div>
                                <div className="px-2">
                                    <Input type="select" defaultValue="">
                                        <option disabled={true} value="">Sort by position</option>
                                        <option></option>
                                        <option></option>
                                        <option></option>
                                    </Input>
                                </div>
                                <div className="ps-2">
                                    <Input type="select" defaultValue="">
                                        <option disabled={true} value="">Show 9</option>
                                        <option></option>
                                        <option></option>
                                        <option></option>
                                    </Input></div>
                            </div>
                            <Row>
                                {
                                    products.map(function (product, i) {

                                        return (
                                            <Col key={"product-item-" + i.toString()} xl={4} md={6} className="pb-5">
                                                <div className="product-card m-3">
                                                    <Badge className="my-badge text-uppercase bg-eurosafe" color="" pill>Chemical product</Badge>
                                                    <p className="py-2 m-0"><b>{product.name}</b></p>
                                                    <div className="product-img-container">
                                                        <LazyLoadImage style={{ aspectRatio: "1/1", objectFit: "contain" }} className="w-100" src={product.images.length > 0 ? product.images[0].src : productImage}></LazyLoadImage>
                                                        <div onClick={() => gotToNewPage("/products/"+product.id.toString())} className="on-hover-product">
                                                                See product                                                        
                                                        </div>
                                                    </div>
                                                    
                                                    <p>{product.short_description.replace("<p>", "").replace("</p>", "")}</p>
                                                    <p className="pt-2"><b>À partir de {product.price}€ HT</b><span className="small text-grey"> - {product.regular_price}€ TTC</span></p>
                                                </div>
                                            </Col>
                                        )


                                    })
                                }
                            </Row>

                        </div>

                    </Col>

                </Row>
            </Container>
            <Container className="pt-5 bg-white">
                <div className="pt-5">

                </div>
            </Container>
        </div>

    )
}

export default Products