import React, { Component, useRef, useEffect, useState, createRef } from "react"

import { Container, Col, Row, Badge } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom'
import useSize from "../utils/useSize.js";
import CountUp from "react-countup";

import PrimaryButton from "../components/primary-button.jsx";
import ProductsPreview from "../components/products-preview.jsx";
import SpecialProducts from "../components/special-products.jsx"
import Testimonial from "../components/testimonial.jsx";
import Carousel from "../components/carousel.jsx";
import pills from "../statics/images/assets/home.jpg"
import admet from "../statics/images/assets/admet-crop.png"
import pichet from "../statics/images/assets/pichet.png"
import scientist from "../statics/images/assets/scientist.png"
import logo from "../statics/images/logos/eurosafe-light.png"
import biopredicLight from '../statics/images/logos/biopredic-light.png'
import eurosafeLight from '../statics/images/logos/eurosafe-light.png'
import starlightLight from '../statics/images/logos/startlight-light.png'
import advancellsLight from '../statics/images/logos/advancells-light.png'

import interestingNews from '../statics/images/assets/interesting-news.png'
import team from '../statics/images/assets/team.png'
import scientificIdea from '../statics/images/assets/scientific-idea.png'

import bannerPicture1 from '../statics/images/assets/people-meeting.jpg'
import bannerPicture2 from '../statics/images/assets/man-calling.jpg'
import bannerPicture3 from '../statics/images/assets/scientist.jpg'
import bannerPicture4 from '../statics/images/assets/woman-calling.jpg'
import bannerPicture5 from '../statics/images/assets/scientific-tool.jpg'
import bannerPicture6 from '../statics/images/assets/interview.jpg'
import bannerPicture7 from '../statics/images/assets/building.jpg'
import Product from "./product.jsx";

const customer_opinion = [
    {
        author: "Gauthier Quéau",
        date: "10/06/2024",
        content: "Vestibulum non ultrices erat. Phasellus porttitor justo et lorem hendrerit maximus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
        css: "bg-secondary"

    },
    {
        author: "Thomas Darde",
        date: "01/05/2024",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ullamcorper purus at metus vehicula, nec scelerisque tortor posuere. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
        css: "bg-warning"
    },
    {
        author: "Alondra",
        date: "21/04/2024",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus porttitor justo et lorem hendrerit maximus.",
        css: "bg-danger"
    },
    {
        author: "Quentin",
        date: "15/03/2024",
        content: "Fusce ullamcorper purus at metus vehicula, nec scelerisque tortor posuere. Vestibulum non ultrices erat. Phasellus porttitor justo et lorem hendrerit maximus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
        css: "bg-primary"
    },
]

class Home extends Component {


    constructor(props) {
        super(props);
        this.state = {
            height: 0,
        };
    }

    componentDidMount() {
        this.setState({
            height: window.innerHeight
        })
    }
    componentDidUpdate() {
        if (window.innerHeight != this.state.height) {
            this.setState({
                height: window.innerHeight
            })
        }
    }



    render() {

        return (
            <div>
                <div className="window bg-white text-center" style={{ height: this.props.isSticky ? this.state.height : (this.state.height - 60) }}>
                    <Container className="h-100" >
                        <Row className="h-100" >
                            <Col md={4}></Col>
                            <Col md={4} className="align-items-center d-flex justify-content-center h-100">
                                <div>
                                    <h2 className="title">Wepredic</h2>
                                    <p className="subtitle">Chemo-biology models for safe products.</p>
                                    <div>
                                        <LazyLoadImage style={{ width: "60%" }} src={pills} />
                                    </div>
                                    <PrimaryButton action={()=>{}} content="Buy" theme="1" />
                                </div>
                            </Col>
                            <Col md={4}></Col>
                        </Row>
                    </Container>
                </div>
                <Container className="pt-3 pb-5">
                    <h3 className="part-title">Product categories. <span className="text-grey">Take a look at Starlight offering.</span></h3>
                    <Row className="py-3">
                        <Col lg={4} className="">
                            <div className="my-card h-100">
                                <div className="py-5 px-4">
                                    <h5>ADMET Products</h5>
                                    <p className="text-small text-muted py-2">+ 200 diluted or powdered chemical <br />products.
                                    </p>
                                </div>
                                <div className="card-image ps-4">
                                    <LazyLoadImage className="w-100" src={admet}></LazyLoadImage>
                                </div>

                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="my-card h-100">
                                <div className="py-5 px-4">
                                    <h5>ADMET Librairy</h5>
                                    <p className="text-small text-muted py-2">Librairies designed for Absorption,<br />Disposition, Metabolism, Elimination and<br />Toxicity domains.
                                    </p>
                                </div>
                                <div className="card-image ps-4 text-end pe-5">
                                    <LazyLoadImage className="w-50" src={pichet}></LazyLoadImage>
                                </div>

                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="my-card h-100">
                                <div className="py-5 px-4">
                                    <h5>On-demand chemistry</h5>
                                    <p className="text-small text-muted py-2">Chemical biology solutions designed<br />products.
                                    </p>
                                </div>
                                <div className="card-image ps-4 text-end pe-5">
                                    <LazyLoadImage className="w-25" src={scientist}></LazyLoadImage>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="bg-theme">
                    <Container className="py-5" >
                        <h3 className="part-title pt-4">We are a <span className="text-white">global biotech.</span> We provide products & solutions.</h3>
                        <Row className="py-3">
                            <Col lg={3}>
                                <div className="my-card h-100">
                                    <div className="py-4 px-4">
                                        <Row className="">
                                            <Col md={2} className="my-auto">
                                                <LazyLoadImage width={40} className="" src={biopredicLight}></LazyLoadImage>
                                            </Col>
                                            <Col className="my-auto text-uppercase small text-grey">
                                                Biopredic<br />
                                                Biological
                                            </Col>
                                        </Row>
                                        <p className="pt-4" style={{ fontSize: "18px" }}>
                                            <b>Liver, Skin, Blood, our 3 products ranges of standard <span className="text-biopredic">assay systems.</span></b>
                                        </p>

                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="my-card h-100">
                                    <div className="py-4 px-4">
                                        <Row className="">
                                            <Col md={2} className="my-auto">
                                                <LazyLoadImage width={40} className="" src={starlightLight}></LazyLoadImage>
                                            </Col>
                                            <Col className="my-auto text-uppercase small text-grey">
                                                Starlight<br />
                                                smart
                                            </Col>
                                        </Row>
                                        <p className="pt-4" style={{ fontSize: "18px" }}>
                                            <b>Our 3 products of <span className="text-starlight">chemical probes</span>: turn-on probes, silencing probes, metabolism probes.</b>
                                        </p>

                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="my-card h-100">
                                    <div className="py-4 px-4">
                                        <Row className="">
                                            <Col md={2} className="my-auto">
                                                <LazyLoadImage width={40} className="" src={eurosafeLight}></LazyLoadImage>
                                            </Col>
                                            <Col className="my-auto text-uppercase small text-grey">
                                                <b>Eurosafe</b><br />
                                                Safety & bioavailability
                                            </Col>
                                        </Row>
                                        <p className="pt-4" style={{ fontSize: "18px" }}>
                                            <b>We provide expertise and new approach methodologies with <span className="text-eurosafe">assessment services.</span></b>
                                        </p>

                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="my-card h-100">
                                    <div className="py-4 px-4">
                                        <Row className="">
                                            <Col md={2} className="my-auto">
                                                <LazyLoadImage width={40} className="" src={advancellsLight}></LazyLoadImage>
                                            </Col>
                                            <Col className="my-auto text-uppercase small text-grey">
                                                <b>Biopredic<br />advancells</b>

                                            </Col>
                                        </Row>
                                        <p className="pt-4" style={{ fontSize: "18px" }}>
                                            <b>Prepares and distributes biologicals for transactional research.</b>
                                        </p>

                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
                <div className="bg-white pt-4">
                    <Container className="pt-3 pb-5">
                        <h3 className="part-title pt-4 text-dark text-end">They trust us and you can trust their products.</h3>
                        <Carousel />
                    </Container>
                </div>
                <div className="bg-light pt-5">
                    <Container className="pt-4 pb-5">
                        <Row>
                            <Col className="my-auto">
                                <h3 className="part-title">Discover. </h3>
                            </Col>
                            <Col>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec neque orci, dictum id neque a, accumsan commodo tortor. Phasellus dui mi, luctus non tellus a, ultrices finibus quam</p>
                            </Col>
                        </Row>

                        <Row className="py-3">
                            <Col lg={4} className="">
                                <div className="my-card h-100">
                                    <div className="pt-5 px-4">
                                        <p className="py-2"><b>Our latest and- we <br />believe - interesting news</b></p>
                                    </div>
                                    <LazyLoadImage className="w-100" src={interestingNews}></LazyLoadImage>

                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="my-card h-100">
                                    <div className="pt-5 px-4">
                                        <p className="py-2"><b>Scientific papers, ideas and receipes get more interesting when shared</b></p>

                                    </div>
                                    <div className="ps-5 text-end">
                                        <LazyLoadImage className="ps-5 w-100" src={scientificIdea}></LazyLoadImage>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="my-card h-100">
                                    <div className="pt-5 px-4">
                                        <p className="py-2"><b>Become part of the team !</b></p>

                                    </div>
                                    <div className="text-end">
                                        <LazyLoadImage className="w-100" src={team}></LazyLoadImage>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <SpecialProducts />
                <div className="bg-dark-blue pt-5">
                    <Container className="pt-4 pb-5">
                        <h3 className="part-title text-white">The biopredic difference. <span className="text-grey">Even more reasons to shop with us.</span></h3>
                        <Row className="py-5 text-white">
                            <Col className="offset-md-4 px-5 py-4" md={4}>
                                <div className="border-bottom h-100">
                                    <Row className="h-100">
                                        <Col className="uge-letter"><CountUp enableScrollSpy duration={5} className="counter" end={6} /></Col>
                                        <Col className="my-auto text-end">
                                            <b>Patents</b><br />
                                            Grated since 1993
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col className="py-4 px-5" md={4}>
                                <div className="border-bottom h-100">
                                    <Row className="h-100">
                                        <Col className="uge-letter my-auto"><CountUp enableScrollSpy duration={5} className="counter" end={35} /></Col>
                                        <Col className="my-auto text-end">
                                            <b>Served countries</b><br />
                                            over the world
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col className="offset-md-4 py-4 px-5" md={4}>
                                <div className="border-bottom h-100">
                                    <Row className="h-100">
                                        <Col className="uge-letter my-auto"><CountUp enableScrollSpy duration={5} className="counter" end={45} /></Col>
                                        <Col className="my-auto text-end">
                                            <b>Employees</b><br />
                                            work together
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col className="py-4 px-5" md={4}>
                                <div className="border-bottom h-100">
                                    <Row className="h-100">
                                        <Col className="big-letter my-auto">+<CountUp enableScrollSpy duration={5} className="counter" end={1000} /></Col>
                                        <Col className="my-auto text-end">
                                            <b>Customers</b><br />
                                            use our products
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div className="bg-primary p-0 m-0" style={{ flex: 1, display: "flex", alignItems: "center", boxSizing: "border-box", justifyContent: "center" }}>
                            <LazyLoadImage style={{ width: "100%", aspectRatio: (1 / 1), objectFit: "cover" }} src={bannerPicture1} />
                        </div>
                        <div className="bg-warning p-0 m-0" style={{ flex: 1, display: "flex", alignItems: "center", boxSizing: "border-box", justifyContent: "center" }} >
                            <LazyLoadImage style={{ width: "100%", aspectRatio: (1 / 1), objectFit: "cover" }} src={bannerPicture2} />
                        </div>
                        <div className="bg-primary p-0 m-0" style={{ flex: 1, display: "flex", alignItems: "center", boxSizing: "border-box", justifyContent: "center" }} >
                            <LazyLoadImage style={{ width: "100%", aspectRatio: (1 / 1), objectFit: "cover" }} src={bannerPicture3} />
                        </div>
                        <div className="bg-warning p-0 m-0" style={{ flex: 1, display: "flex", alignItems: "center", boxSizing: "border-box", justifyContent: "center" }} >
                            <LazyLoadImage style={{ width: "100%", aspectRatio: (1 / 1), objectFit: "cover" }} src={bannerPicture4} />
                        </div>
                        <div className="bg-primary p-0 m-0" style={{ flex: 1, display: "flex", alignItems: "center", boxSizing: "border-box", justifyContent: "center" }} >
                            <LazyLoadImage style={{ width: "100%", aspectRatio: (1 / 1), objectFit: "cover" }} src={bannerPicture5} />
                        </div>
                        <div className="bg-warning p-0 m-0" style={{ flex: 1, display: "flex", alignItems: "center", boxSizing: "border-box", justifyContent: "center" }} >
                            <LazyLoadImage style={{ width: "100%", aspectRatio: (1 / 1), objectFit: "cover" }} src={bannerPicture6} />
                        </div>
                        <div className="bg-primary p-0 m-0" style={{ flex: 1, display: "flex", alignItems: "center", boxSizing: "border-box", justifyContent: "center" }} >
                            <LazyLoadImage style={{ width: "100%", aspectRatio: (1 / 1), objectFit: "cover" }} src={bannerPicture7} />
                        </div>
                    </div>
                </div>
                <div className="bg-theme py-5">
                    <Container className="border-top border-white">
                        <Row className="py-5">
                            <Col md={6} className="my-auto">
                                <h3 className="part-title text-white">Testimonials.</h3>
                                <p className="part-title subtitle">What our customers are saying.</p>
                            </Col>
                            <Col md={6}>
                                <Testimonial content={customer_opinion} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className="py-5">
                    <h3 className="part-title py-4 text-dark text-end">Focus on genotoxic. <span className="text-grey">Selection for our [...].</span> </h3>
                    <ProductsPreview />
                </Container>
            </div>
        )
    }

}

export default Home

