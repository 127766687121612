import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Row, Col, Container, DropdownMenu, Dropdown,
    DropdownToggle,
    DropdownItem
} from "reactstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import wepredic from '../statics/images/logos/wepredic.png'

import biopredicLight from '../statics/images/logos/biopredic-light.png'
import eurosafeLight from '../statics/images/logos/eurosafe-light.png'
import starlightLight from '../statics/images/logos/startlight-light.png'
import advancellsLight from '../statics/images/logos/advancells-light.png'
import PrimaryButton from './primary-button';

const MyNavbar = (props) => {

    const navigate = useNavigate()
    const gotToNewPage = (route) => {
        navigate(route);
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

    // const [isSticky, setIsSticky] = useState(false);
    const elementRef = useRef(null);


    const [dropdownData, setDropdownData] = useState(null)
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const handleClickFirst = () => {
        if (dropdownData !== 0) {
            setDropdownVisible(true)
            setDropdownData(0)
        } else {
            setDropdownVisible(false);
            setDropdownData(null)
        }

    }

    const handleClickSecond = () => {
        if (dropdownData !== 1) {
            setDropdownVisible(true)
            setDropdownData(1)
        } else {
            setDropdownVisible(false);
            setDropdownData(null)
        }
    }

    const handleClickThird = () => {
        if (dropdownData !== 2) {
            setDropdownVisible(true)
            setDropdownData(2)
        } else {
            setDropdownVisible(false);
            setDropdownData(null)
        }
    }

    const handleClickFourth = () => {
        if (dropdownData !== 3) {
            setDropdownVisible(true)
            setDropdownData(3)
        } else {
            setDropdownVisible(false);
            setDropdownData(null)
        }
    }


    useEffect(() => {

        const height = elementRef.current.offsetHeight + 45
        if (height != elementRef.current.offsetHeight + 45) {
            props.editNavBar(height)
            height = elementRef.current.offsetHeight + 45
        }

        const handleScroll = () => {
            if (elementRef.current) {
                const offsetTop = elementRef.current.getBoundingClientRect().top + 45;

                if (window.scrollY > offsetTop) {
                    props.editSticky(true);
                } else {
                    props.editSticky(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);


    const dropdownItem = [
        [
            {
                title: "Starlight",
                icon: <LazyLoadImage className="pe-2" style={{ marginBottom: "2px !important" }} height="auto" width={30} src={starlightLight} />,
                baseline: 'Smart Chemical probes',
                links: [
                    {
                        title: "ADMET Products",
                        link: "/products/admet-products"
                    },
                    {
                        title: "ADMET Librairie",
                        link: "/products/admet-librairies"
                    },
                    {
                        title: "On-demand chemistry",
                        link: "/products/on-demande-chemistry"
                    }
                ]

            },
            {
                title: "Biopredic",
                icon: <LazyLoadImage className="pe-2" style={{ marginBottom: "2px !important" }} height="auto" width={30} src={biopredicLight} />,
                baseline: 'Biological Assay systems',
                links: [
                    {
                        title: "Liver assay systems",
                        link: "/products"
                    },
                    {
                        title: "Skin assay systems",
                        link: "/products"
                    },
                    {
                        title: "Blood assay systems",
                        link: "/products"
                    }
                ]
            },
            {
                title: "Eurosafe",
                icon: <LazyLoadImage className="pe-2" style={{ marginBottom: "2px !important" }} height="auto" width={30} src={eurosafeLight} />,
                baseline: 'Safety & bioavailability\n Assessment services',
                links: [
                    {
                        title: "In Vitro Assay",
                        link: "/products"
                    },
                    {
                        title: "Tests on volunteers",
                        link: "/products"
                    },
                    {
                        title: "Toxicology & regulatory expertise",
                        link: "/products"
                    },
                    {
                        title: "In silico modelling",
                        link: "/products"
                    }
                ]
            },
            {
                title: "Biopredic Advancells",
                icon: <LazyLoadImage className="pe-2" style={{ marginBottom: "2px !important" }} height="auto" width={30} src={advancellsLight} />,
                baseline: 'None',
                links: [
                    {
                        title: "None",
                        link: "/products"
                    },
                    {
                        title: "None",
                        link: "/products"
                    }
                ]
            }
        ],
        [
            {
                icon: <></>,
                links: [
                    {
                        title: "Latest news",
                        link: "/products"
                    },
                    {
                        title: "Filter by company",
                        link: "/products"
                    }
                ]

            },



        ],
        [
            {
                title: "Biotech company",
                icon: <></>,
                baseline: '',
                links: [
                    {
                        title: "Wepredic",
                        link: "/products",
                        description: "Modeling chemo-biology for safe products"
                    },
                    {
                        title: "Starlight",
                        link: "/products",
                        description: "Designs and syntheses smart chemical probes, for the pharmacokinetics and the safety of xenobiotics"
                    },
                    {
                        title: "Biopredic",
                        link: "/products",
                        description: "Manufactures assay systems from human and animal biological materials for the industrial and academic research."
                    },
                    {
                        title: "Eurosafe",
                        link: "/products",
                        description: "Provides assessment services for the safety and fate of drugs, cosmetics and chemicals."
                    },
                    {
                        title: "Biopredic Advancells",
                        link: "/products",
                        description: "Prepares and distributes biologicals for translational research."
                    }
                ]

            },

            {
                title: "Careers",
                icon: <></>,
                baseline: '',
                links: [
                    {
                        title: "Meet the team",
                        link: "/products"
                    },
                    {
                        title: "Join us",
                        link: "/products"
                    }
                ]
            },

        ],
        [
            {
                title: "What We do",
                icon: <></>,
                baseline: '',
                links: [
                    {
                        title: "Smart chemical probes",
                        link: "/products"
                    },
                    {
                        title: "Biological Systems",
                        link: "/products"
                    },
                    {
                        title: "Safety & bioavailibility assessment services",
                        link: "/products"
                    },
                    {
                        title: "None",
                        link: "/products"
                    }
                ]

            },
            {
                title: "Focus on our services",
                icon: <></>,
                baseline: '',
                links: [
                    {
                        title: "Standard services",
                        link: "/products"
                    },
                    {
                        title: "Premium services",
                        link: "/products"
                    }
                ]
            },

        ],
    ]

    return (
        <div style={{ zIndex: 99, paddingTop: props.isSticky ? "" : "45px" }} ref={elementRef} className={'sticky w-100'}>
            <Navbar className="w-100 px-5 bg-navbar" expand="md">
                <NavbarBrand className="pl-3 my-auto" href="/"><LazyLoadImage style={{ marginBottom: "2px" }} height="auto" width={40} src={wepredic} /> <span className='text-wepredic small'><b>Wepredic</b></span></NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto w-100 justify-content-center"
                        navbar>
                        <NavItem className="px-md-4" onClick={handleClickFirst} >
                            <div className={isDropdownVisible && dropdownData === 0 ? "nav-link border-bottom" : "nav-link"} to=""><b>Buy Products</b></div>
                        </NavItem>
                        <NavItem className="px-md-4">
                            <Link className="nav-link text-dark" to={"news"}>{"News"}</Link>
                        </NavItem>
                        <NavItem className="px-md-4" onClick={handleClickThird} >
                            <div className={isDropdownVisible && dropdownData === 2 ? "nav-link border-bottom" : "nav-link"} to=""><b>Our Group</b></div>
                        </NavItem>
                        <NavItem className="px-md-4" onClick={handleClickFourth} >
                            <div className={isDropdownVisible && dropdownData === 3 ? "nav-link border-bottom" : "nav-link"} to=""><b>Our services</b></div>
                        </NavItem>
                    </Nav>

                </Collapse>

                {/* <NavItem className="w-100 text-left px-3">
                        <Link className="nav-link text-dark" to="/products/1"></Link>
                    </NavItem> */}
                <Nav>
                    <Dropdown isOpen={dropdownOpen} className='dropdown-btn position-relative p-1 px-2' toggle={toggleDropdown} direction='down'>
                        {props.cart.counter > 0 ? <div className='position-absolute cart-pin small'>{props.cart.counter}</div> : <></>}
                        <DropdownToggle className='p-0 m-0 text-dark dropdown-toggle-btn bg-transparent border-0 '><FontAwesomeIcon icon={faCartShopping} /></DropdownToggle>
                        <DropdownMenu style={{ marginTop: props.isSticky ? "0px" : "45px" }} container={'body'}>
                            <Container className='small text-grey text-center py-2'>
                                {props.cart.counter === 0 ? <>{props.cart.counter}</> :
                                    <>
                                        {
                                            props.cart.items.map((item) => {
                                                return (
                                                    <div className='bg-white p-2 d-flex justify-content-between flex-row small mb-2'>
                                                        <div className='pe-3'>{item.quantity} x <Link to={"/products/" + item.id} className='text-dark'>{item.name}</Link></div> {item.price * item.quantity} € TTC
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className='bg-white p-2 small'>
                                            <div className='d-flex justify-content-between flex-row  '>
                                                <div className='pe-3'>Total</div> {props.cart.total} € TTC
                                            </div>
                                            <PrimaryButton action={() => gotToNewPage("/cart/")} customClasses="py-2 radius-small" content="View and edit cart" theme="1" />
                                        </div>

                                    </>
                                }

                            </Container>
                        </DropdownMenu>
                    </Dropdown>
                </Nav>

            </Navbar>
            <div className={isDropdownVisible ? 'w-100 dropdown d-block' : 'w-100 dropdown d-none'}>
                <Container className='py-3'>
                    <Row className='p-4 justify-content-center'>
                        {
                            dropdownData === null ? <></> : dropdownItem[dropdownData].map((item, index) =>
                                <Col key={index + "dropdown"} md={3}>
                                    {
                                        item.title ?
                                            <div className='d-flex my-auto'>
                                                <div>{item.icon}</div>
                                                <h5 className='m-0'><b>{item.title}</b></h5>

                                            </div>
                                            : <></>
                                    }
                                    {item.baseline ? <p className='text-grey small'>{item.baseline}</p> : <></>}
                                    {
                                        item.links.map((link) =>
                                            <div className='py-2'>
                                                <Link onClick={handleClickFirst} className="nav-link text-dark" to={link.link}>{link.title}</Link>
                                                <div className='small text-grey'>{link.description}</div>
                                            </div>
                                        )
                                    }

                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </div>
        </div>




    )
}


export default MyNavbar