import React, { Component, useEffect, useState } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Error404 from "./404"
import productImage from '../statics/images/assets/product-image.jpg'
import PrimaryButton from "../components/primary-button.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import logo from '../statics/images/logos/wepredic.png'


const Register = (props) => {


    useEffect(() => {

    })


    return (
        <div className="pt-5">
            <Container className="py-4">
                <div className="w-100 text-center pt-5">
                    <div className="w-50 mx-auto">
                        <LazyLoadImage width={60} height={"auto"} src={logo} />
                        <h2 className="part-title pb-0 pt-2 text-dark"><b>Ravi de vous rencontrer</b></h2>
                        <p className=" pt-0 ">To get information about our products and services,
                            or to simply meet us, don’t hesitate to contact us.</p>
                    </div>
                </div>
                <Row className="pt-3">
                    <Col md={4}></Col>
                    <Col md={4}>
                    <p className="text-center text-muted mb-1">Create your Wepredic Member profile and ....Dire aux utilisateurs pourquoi cela vaut la peine de s’inscrire (même si c’est une étape obligatoire).</p>
                    <p className="text-center text-muted small p-0 m-0">Ex: Indiquer sous forme de liste à puces, un, deux ou trois avantages majeurs. “Payer en ligne. Suivez les étapes de traitement de vos commandes”.</p>
                        <Form className="pt-4">
                            <FormGroup>
                                <Label className="mb-0 small text-muted">E-mail</Label>
                                <Input type="email" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Password</Label>
                                <Input type="password" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Confirm password</Label>
                                <Input type="password" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">First name</Label>
                                <Input type="text" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Last name</Label>
                                <Input type="text" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Company's name</Label>
                                <Input type="password" />
                            </FormGroup>
                            <FormGroup>
                                <Input type="checkbox" />
                                <Label className="small text-muted">You agree to Wepredic's <a className="text-dark" href="">Privacy policy</a> and <a className="text-dark" href="">Terms of use</a>.</Label>
                            </FormGroup>
                            <FormGroup>
                                <PrimaryButton customClasses="w-100 mt-1" action={() => { }} content="Create my account" theme="1" />
                            </FormGroup>
                            <div className="w-100 text-center">
                                <p className="small text-grey">Already an account? <a className="text-dark" href="">Sign in</a></p>
                            </div>
                        </Form>
                    </Col>
                </Row>



            </Container>
        </div>
    )
}

export default Register