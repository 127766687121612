import React from "react";
import { Container, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faCreditCard, faBox } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
        <div className="bg-light py-3" >
            <Container className="py-5">
                <Row className="pb-5 border-bottom ">
                    <Col md={3}>
                        <p><b>Services</b></p>
                        <Link className="nav-link pb-2">Returns & Exchanges</Link>
                        <Link className="nav-link pb-2">Your Online Account</Link>
                        <Link className="nav-link pb-2">Order history</Link>
                        <Link className="nav-link pb-2">Shipping info</Link>
                        <Link className="nav-link pb-2">Membership</Link>
                        <Link className="nav-link pb-2">Find Member Number</Link>
                    </Col>
                    <Col md={3}>
                        <p><b>Who we are</b></p>
                        <Link className="nav-link pb-2">Wepredic</Link>
                        <Link className="nav-link pb-2">Starlight</Link>
                        <Link className="nav-link pb-2">Eurosafe</Link>
                        <Link className="nav-link pb-2">Biopredic</Link>
                        <Link className="nav-link pb-2">Biopredic Advancells</Link>
                    </Col>
                    <Col md={3}>
                        <p><b>Expertise</b></p>
                        <Link className="nav-link pb-2">Our Expertises in biological and chemical systems</Link>
                        <Link className="nav-link pb-2">Biological Assay Systems</Link>
                        <Link className="nav-link pb-2">Chemical Assay Systems</Link>
                        <Link className="nav-link pb-2">Safety & bioavailability assessment services</Link>
                    </Col>
                    <Col md={3}>
                        <p><b>About</b></p>
                        <Link className="nav-link pb-2">News</Link>
                        <Link className="nav-link pb-2">Applications</Link>
                        <Link className="nav-link pb-2">Pledges</Link>
                        <Link className="nav-link pb-2">Careers</Link>
                    </Col>
                </Row>
                <Row className="pt-4 pb-5 border-bottom ">
                    <Col md={4}>
                        <p className="pb-0 mb-0"><FontAwesomeIcon icon={faPhone} />  <b><u>Services</u></b></p>
                        <p className="text-grey pt-0 mt-0">Mond-Fri, 8am -6 pm CET</p>
                    </Col>
                    <Col md={4}>
                        <p className="pb-0 mb-0"><FontAwesomeIcon icon={faCreditCard} />  <b><u>Visa, Mastercard, Amex accepted</u></b></p>
                        <p className="text-grey pt-0 mt-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    </Col>

                    <Col md={4}>
                        <p className="pb-0 mb-0"><FontAwesomeIcon icon={faBox} />  <b><u>48h delivery</u></b></p>
                        <p className="text-grey pt-0 mt-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    </Col>
                </Row>
                <Row className="py-4 text-center">
                    <Col md={12}>
                        <p className="">2021 All rights reserved. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tincidunt pulvinar felis in mattis. </p>
                    </Col>
                    <Col className="text-end" md={4}>
                        <Link className="nav-link pb-2"><b>Legals</b></Link>
                    </Col>
                    <Col md={4}>
                        <Link className="nav-link pb-2"><b>Terms of Use</b></Link>
                    </Col>
                    <Col className="text-start" md={4}>
                        <Link className="nav-link pb-2"><b>Privacy Policy</b></Link>
                    </Col>

                </Row>
            </Container>
        </div>

    )
}

export default Footer
