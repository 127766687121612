import React, { Component, useEffect, useRef, useState } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Collapse } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom'
import Breadcrump from "../components/breadcrump";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'

import logo from '../statics/images/logos/wepredic.png'
import plus from '../statics/images/icons/plus.png'
import PrimaryButton from "../components/primary-button.jsx";

const Contact = (props) => {

    const navigate = useNavigate()
    const cardRef = useRef()
    const [isForm, setIsForm] = useState(false)
    const previews_links = [
        { route: "/", label: "Home" },
        { route: "", label: "Contact" },
    ]

    const [cardHeight, setCardHeight] = useState(null)
    useEffect(() => {
        if (cardHeight === null) {
            setCardHeight(cardRef.current.clientHeight)
        }
    })

    return (
        <div className="pt-5">
            <Container className="py-4">
                <Breadcrump previews_links={previews_links} />
                <div className="w-100 text-center py-5">
                    <div className="w-50 mx-auto">
                        <LazyLoadImage width={60} height={"auto"} src={logo} />
                        <h2 className="part-title pb-0 pt-2 text-dark"><b>Contact</b></h2>
                        <p className=" pt-0 ">To get information about our products and services,
                            or to simply meet us, don’t hesitate to contact us.</p>
                    </div>
                </div>
                <Row className="py-4 mb-5 pb-5">
                    <Col md={6}>
                        <div ref={cardRef} className="p-5 my-card">
                            <p className="part-title">Contact via formulaire</p>
                            <p>Délai de réponse moyen: 72heures.
                                Après envoi du formulaire, vous recevrez un mail de confirmation dans votre boîte mail.</p>
                            <div className="w-100 text-center">
                                <div className={!isForm ? "d-block" : "d-none"}><PrimaryButton action={() => setIsForm(!isForm)} content="Afficher le formulaire" theme="1" /></div>
                                <div className={isForm ? "d-block" : "d-none"}><PrimaryButton action={() => setIsForm(!isForm)} content="Masquer le formulaire" theme="4" /></div>
                            </div>
                            <Collapse isOpen={isForm} className="pt-3">
                                <Form>
                                    <FormGroup>
                                        <Label className="mb-0 small text-muted">
                                            Full name
                                        </Label>
                                        <Input type="text" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="mb-0 small text-muted">
                                            Your company
                                        </Label>
                                        <Input type="text" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="mb-0 small text-muted">
                                            Phone
                                        </Label>
                                        <Input type="text" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="mb-0 small text-muted">
                                            Email address
                                        </Label>
                                        <Input type="email" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="mb-0 small text-muted">
                                            Subject
                                        </Label>
                                        <Input type="select">
                                            <option selected disabled>-- Select --</option>
                                            <option>Exemple 1</option>
                                            <option>Exemple 2</option>
                                            <option>Exemple 3</option>
                                            <option>Exemple 4</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="small text-muted">
                                            Attachement files
                                        </Label>
                                        <br />
                                        <Row>
                                            <Col md={7}>
                                                <Input id="inputfile" className="inputfile" type="file" />
                                                <Label htmlFor="inputfile" className="mb-0">
                                                    <FontAwesomeIcon icon={faFileUpload} /> <span>Choose file</span>
                                                </Label>
                                            </Col>
                                            <Col className="my-auto">
                                                <p className="small text-grey m-0 p-0">Formats: PDF, jpg</p>
                                                <p className="small text-grey m-0 p-0">10Mo max.</p>
                                            </Col>
                                        </Row>


                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="mb-0 small text-muted">
                                            Message
                                        </Label>
                                        <Input type="textarea" />
                                    </FormGroup>
                                    <PrimaryButton customClasses="w-100 mt-3" action={() => { }} content="Send" theme="1" />
                                </Form>
                            </Collapse>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={{ height: cardHeight }} className="p-5 my-card bg-dark-blue text-white d-flex justify-content-between">
                            <p className="part-title">Contact via téléphone</p>
                            <div>
                                <div className="w-100 d-flex flex-row justify-content-between py-2">
                                    <div>Nom du service #1</div>
                                    <LazyLoadImage src={plus} />
                                </div>
                                <hr className="py-0 my-0" />
                                <div className="w-100 mt-0 d-flex flex-row justify-content-between py-2">
                                    <div>Nom du service #2</div>
                                    <LazyLoadImage src={plus} />
                                </div>
                            </div>


                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Contact