import React, { useEffect } from "react";
import { DNA } from 'react-loader-spinner'

export default function Loader() {

    useEffect(() => {
        document.body.style.overflow = "hidden";
        
    }, []);
    


    return (
        <div style={{ zIndex: 999999999999999 }} className="position-absolute h-100 w-100 loader no-scroll">
            <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        </div>
    )
}