import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'


export default function Testimonial(props) {


    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      lazyLoad: true,
      nextArrow: <></>,
      prevArrow: <></>
    };

    return (
        <div className="py-3 testimonial text-white">
            <div className="py-2" style={{ textAlign: "end", fontSize: "20px" }}>
                <FontAwesomeIcon className="pe-4" onClick={previous} icon={faArrowLeftLong} />
                <FontAwesomeIcon onClick={next} icon={faArrowRightLong} />
            </div>
            <Slider ref={slider => {sliderRef = slider}} 
                {...settings}>
                
                {
                    props.content.map((item, index)=>
                        <div key={'testimonial-'+index} className='py-3 testimonial-item'>
                            <p className='testimonial-content m-0 p-0'>{item.content}</p>
                            <p className='mt-5 small'>{item.author}, {item.date}</p>
                        </div>
                    )
                }
       
                    
            </Slider>
        </div>
      
    );
  }