import React, { Component, useRef, useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import Loader from "./components/loader.jsx"
import MyNavbar from "./components/navbar"
import Overbar from "./components/overbar"
import Home from "./pages/home"
import Post from "./pages/post"
import Products from "./pages/products"
import Product from "./pages/product"
import Contact from "./pages/contact"
import Register from "./pages/register"
import Login from "./pages/login"
import News from "./pages/news"
import Cart from "./pages/cart"
import Order from "./pages/order"
import Error404 from "./pages/404"
import Footer from "./components/footer.jsx";
import { Audio } from 'react-loader-spinner'

import Woocommerce from "./utils/woocommerce";
import axios from "axios"
import OnDemand from "./pages/on-demand.jsx"

import Cookies from 'universal-cookie'
import CartClass from "./classes/cart"

const cookies = new Cookies()
const current = new Date();
const nextYear = new Date();

nextYear.setFullYear(current.getFullYear() + 1);

class RoutesManager extends Component {

    constructor(props) {
        super(props);
        this.userCookie = cookies.get("user")
        this.cartCookie = cookies.get("cart")
        this.state = {
            isSticky: false,
            isStickyFilter: false,
            navbarHeight: 0,
            store: [],
            cart: {
                items: [],
                total: 0,
                counter: 0
            },
            articles: [],
            isLoaded: false,
            config: {
                apiUrl : process.env.NODE_ENV == "production" ? 'https://dev-shop-api.wepredic.com/api/' : "http://localhost:8539/api/"
            }
        }


    }



    componentDidMount() {
        this.getCookieCart()
        this.getStore()
        this.getArticles()
    }

    componentDidUpdate() {
        const regex_news = /news\/\d+/;
        const regex_products = /products\/\d+/;

        if (!regex_products.test(window.location.pathname) && !regex_news.test(window.location.pathname) && this.state.store.length > 0 && this.state.articles.length > 0 && this.state.isLoaded === false) {
            this.setState({
                isLoaded: true
            })
        }


    }

    getCookieCart() {
        const savedCart = cookies.get('cart');
        if (savedCart) {
            this.setState({
                cart: savedCart
            })
        }
    }


    addToCart = (item, quantity) => {

        if (!this.state.cart.items.some(e => e.id === item.id)) {
            const newCart = {
                items: [...this.state.cart.items, { id: item.id, quantity: parseInt(quantity), price: item.price, name: item.name, img: item.images[0].src }],
                total: this.state.cart.total + (item.price * parseInt(quantity)),
                counter: this.state.cart.counter + parseInt(quantity)
            }
            this.setState({
                cart: newCart
            })
            const cookies = new Cookies()
            cookies.set('cart', { 'items': [...newCart.items], 'counter': newCart.counter, "total": newCart.total }, { path: '/', expires: nextYear });
        } else {
            this.state.cart.items.map((e) => {
                if (e.id === item.id) {
                    e.quantity = parseInt(e.quantity) + parseInt(quantity)

                    const newCart = {
                        items: [...this.state.cart.items],
                        total: this.state.cart.total + (item.price * parseInt(quantity)),
                        counter: this.state.cart.counter + parseInt(quantity)
                    }
                    this.setState({
                        cart: newCart
                    })
                    const cookies = new Cookies()
                    cookies.set('cart', { 'items': [...newCart.items], 'counter': newCart.counter, "total": newCart.total }, { path: '/', expires: nextYear });
                }
            })
        }
    }


    setLoaded = (loaded) => {
        this.setState({
            isLoaded: loaded
        });
    }

    editSticky = (sticky) => {
        this.setState({
            isSticky: sticky
        });
    }

    editStickyFilter = (sticky) => {
        this.setState({
            isStickyFilter: sticky
        });
    }

    editNavBar = (height) => {
        this.setState({
            navbarHeight: height
        })
    }


    minusQuantity = (product) => {
        this.state.cart.items.map((e) => {
            if (e.id === product.id) {
                let items = this.state.cart.items
                if (e.quantity === 0)
                    return null

                if (e.quantity === 1) {
                    items = this.state.cart.items.filter((item) => item.id !== product.id)
                }
                e.quantity--
                const newCart = {
                    items: [...items],
                    total: this.state.cart.total - product.price,
                    counter: this.state.cart.counter - 1
                }
                this.setState({
                    cart: newCart
                })
                const cookies = new Cookies()
                cookies.set('cart', { 'items': [...newCart.items], 'counter': newCart.counter, "total": newCart.total }, { path: '/', expires: nextYear });
            }
        })
    }

    resetCart = () => {
        this.setState({
            cart: {
                items: [],
                total: 0,
                counter: 0
            },
        })
        const cookies = new Cookies()
        cookies.set('cart', { 'items': [], 'counter': 0, "total": 0 }, { path: '/', expires: nextYear });
    }

    removeItemFromCart = (product) => {
        const items = this.state.cart.items.filter((item) => item.id !== product.id)
        const newCart = {
            items: [...items],
            total: this.state.cart.total - (product.price * product.quantity),
            counter: this.state.cart.counter - product.quantity
        }
        this.setState({
            cart: newCart
        })
        const cookies = new Cookies()
        cookies.set('cart', { 'items': [...newCart.items], 'counter': newCart.counter, "total": newCart.total }, { path: '/', expires: nextYear });
    }



    async getStore() {
        const url = this.state.config.apiUrl+'products'
        try {
            const response = await axios.get(url)
            this.setState({
                store: response.data,
            })
            document.body.style.overflow = "scroll"
        } catch (error) {
            throw error
        }

    }

    async getArticles() {
        const url = this.state.config.apiUrl+'articles'
        try {
            const response = await axios.get(url)
            this.setState({
                articles: response.data,
            })
        } catch (error) {
            console.log(error)
        }
    }


    render() {

        return (
            <Router>
                <div >
                    {!this.state.isLoaded ? <Loader></Loader> : <></>}
                    <Overbar />
                    <MyNavbar editSticky={this.editSticky} editNavBar={this.editNavBar} isSticky={this.state.isSticky} cart={this.state.cart} />
                    <Routes>
                        <Route path="" element={<Home navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="/products/admet-products" element={<Products editStickyFilter={this.editStickyFilter} isStickyFilter={this.state.isStickyFilter} store={this.state.store} navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="/products/admet-librairies" element={<Products editStickyFilter={this.editStickyFilter} isStickyFilter={this.state.isStickyFilter} store={this.state.store} navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="products" element={<Products isLoaded={this.state.isLoaded} editStickyFilter={this.editStickyFilter} isStickyFilter={this.state.isStickyFilter} store={this.state.store} navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="products/:id" element={<Product config={this.state.config}  cartCookie={this.cartCookie} cookies={cookies} setLoaded={(p) => this.setLoaded(p)} addToCart={this.addToCart} cart={this.state.cart} setStateRoot={(p, cb) => this.setState(p, cb)} isLoaded={this.state.isLoaded} editStickyFilter={(p) => this.editStickyFilter(p)} isStickyFilter={this.state.isStickyFilter} store={this.state.store} navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} setCartCounter={this.setCartCounter} setCartTotal={this.setCartTotal} />} />
                        <Route path="news" element={<News articles={this.state.articles} setLoaded={this.setLoaded} isLoaded={this.state.isLoaded} navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="news/:id" element={<Post config={this.state.config} setLoaded={this.setLoaded} getArticles={this.getArticles} articles={this.state.articles} isLoaded={this.state.isLoaded} navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="products/on-demande-chemistry" element={<OnDemand navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="contact" element={<Contact navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="cart" element={<Cart cart={this.state.cart} removeItemFromCart={this.removeItemFromCart} resetCart={this.resetCart} addToCart={this.addToCart} minusQuantity={(product) => this.minusQuantity(product)} navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="order" element={<Order cart={this.state.cart}  navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="login" element={<Login navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="register" element={<Register navbarHeight={this.state.navbarHeight} isSticky={this.state.isSticky} />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                    <Footer />
                </div>
            </Router>

        )
    }


}
export default RoutesManager