import { useState, useEffect, useRef } from 'react';
import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Badge } from 'reactstrap'

import productImage from '../statics/images/assets/product.png'

const products = [
    {
        type: "Chemical product",
        name: "CYP1A2 Subtrat Phanecetin",
        description: "Primary hepatocytes are widely used as the gold-standard in-vitro liver model for biological, pharmacological and toxicological studies in drug discovery and development",
        price: 37.50,
        priceTaxes: 44.60,
        badgeColor: "bg-starlight-dark"
    },
    {
        type: "Chemical product",
        name: "CES2 Metabolite - 6a-Methylprednisolone",
        description: "Primary hepatocytes are widely used as the gold-standard in-vitro liver model for biological.",
        price: 37.50,
        priceTaxes: 44.60,
        badgeColor: "bg-eurosafe"

    },
    {
        type: "Chemical product",
        name: "GST substrat - 1-chloro-2,4-dinitrobenzene",
        description: "Primary hepatocytes are widely used as the gold-standard in-vitro liver model for biological, pharmacological and toxicological studies in drug discovery and development",
        price: 37.50,
        priceTaxes: 44.60,
        badgeColor: "bg-biopredic"

    },
    {
        type: "Chemical product",
        name: "CES1 Substrat - Clopidogrel",
        description: "Primary hepatocytes are widely used as the gold-standard in-vitro liver model for biological, pharmacological and toxicological studies in drug discovery and development",
        price: 37.50,
        priceTaxes: 44.60,
        badgeColor: "bg-starlight-dark"

    },
]

export default function ProductsPreview(props) {


    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        variableWidth: false,
        lazyLoad: true,
        nextArrow: <></>,
        prevArrow: <></>
    };

    return (
        <div className="py-3 product-preview">
            <div className="py-2" style={{ textAlign: "end", fontSize: "20px" }}>
                <FontAwesomeIcon className="pe-4" onClick={previous} icon={faArrowLeftLong} />
                <FontAwesomeIcon onClick={next} icon={faArrowRightLong} />
            </div>
            <Slider className=""  ref={slider => { sliderRef = slider }}
                {...settings}>

                {
                    products.map((item, index) =>
                        <div key={"main-product-"+index} className='carousel-item p-3'>
                            <div className="my-card h-100">
                                <div className="p-4 d-flex flex-column h-100">
                                    <div>
                                        <Badge className={"my-badge text-uppercase " + item.badgeColor.toString()} color="" pill>{item.type}</Badge>
                                        <p className="py-3 m-0"><b>{item.name}</b></p>
                                        <LazyLoadImage className="w-100" src={productImage} />
                                        <p className="small text-muted pb-3">{item.description}</p>
                                    </div>
                                    <p className="small mt-auto mb-0 "><b>À partir de {item.price}€ HT - <span className="text-grey">{item.priceTaxes}€ TTC</span></b></p>

                                </div>

                            </div>
                        </div>
                    )
                }

            </Slider>
        </div>

    );
}