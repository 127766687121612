import { Button } from 'reactstrap'
import React, { Component, useRef, useEffect, useState } from "react"

import './../App.css'


class PrimaryButton extends Component {

    render(){
        return (
            <Button onClick={this.props.action} className={'primary-button theme-'+this.props.theme + " " + this.props.customClasses} >
                { this.props.content === undefined ? "": this.props.content}
            </Button>
        )
    }
    
}

export default PrimaryButton