import React, { Component, useEffect, useState } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Error404 from "./404"
import productImage from '../statics/images/assets/product-image.jpg'
import PrimaryButton from "../components/primary-button.jsx";
import SpecialProducts from "../components/special-products.jsx"
import Breadcrump from "../components/breadcrump";
import CartClass from "../classes/cart"
import withRouter from '../components/with-router';
import Cookies from 'universal-cookie'

import axios from "axios"



class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            product: null,
            toggle: '1',
            quantity: 1,
        };
        this.onChangeInput = this.onChangeInput.bind(this);
        this.previews_links = [
            { route: "/", label: "Home" },
            { route: "/products", label: "Products" },
        ]
    }

    // let { id } = useParams();


    componentDidMount() {
        if (this.state.product === null && this.props.isLoaded) {
            this.props.setStateRoot({
                isLoaded: false
            }, () => { })
        }
        this.getProduct().then(() => {
            this.props.setStateRoot({
                isLoaded: true
            }, () => { })
        })

        if (this.props.isLoaded && this.state.product === undefined) {
            return <Error404 />
        }
    }

    async getProduct() {
        const url = this.props.config.apiUrl+'product/'
        try {
            const response = await axios.get(url + this.props.params.id)
            this.setState({
                product: response.data,
            })
            document.body.style.overflow = "scroll";
        } catch (error) {
            console.log(error)
        }
    }


    onChangeInput(e) {
        this.setState({
            quantity: e.target.value
        })
    }

    render() {


        return (
            <div className="pt-5">

                {this.state.product != null ?
                    <>
                        <Container className="py-4">
                            <Breadcrump previews_links={this.previews_links} />

                            <Row className="pt-3 h-100">
                                <Col md={6} className=" py-4 d-flex flex-column justify-content-between h-auto">
                                    <div>
                                        <p className="py-0 my-0 text-muted small">{this.state.product.categories[0].name}</p>
                                        <h3 className="part-title">{this.state.product.name}</h3>
                                        <p>From {this.state.product.regular_price}€HT</p>
                                        <p className="small">{this.state.product.short_description.replace("<p>", "").replace("</p>", "")}</p>
                                    </div>

                                    <FormGroup>
                                        <Row>
                                            <Col md={4} className="my-auto">Quantity</Col>
                                            <Col md={8} className="my-auto">
                                                <Input id="quantity" name="quantity" min={1} onChange={val => this.onChangeInput(val)} value={this.state.quantity} type="number" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <PrimaryButton action={() => this.props.addToCart(this.state.product, this.state.quantity)} content="Add to cart" theme="1" />

                                </Col>
                                <Col md={6} className="px-5">
                                    <div className="p-0 p-md-5">
                                        <LazyLoadImage style={{ aspectRatio: 1 / 1, objectFit: "contain" }} className="w-100 h-100" src={this.state.product.images.length > 0 ? this.state.product.images[0].src : productImage}></LazyLoadImage>
                                        <div className="d-flex flex-row pt-2">
                                            <div className="product-img-small-card me-2" >
                                                <LazyLoadImage style={{ aspectRatio: 1 / 1, objectFit: "contain" }} className="w-100 p-1" src={this.state.product.images.length > 0 ? this.state.product.images[0].src : productImage}></LazyLoadImage>
                                            </div>
                                            <div className="product-img-small-card me-2">
                                                <LazyLoadImage style={{ aspectRatio: 1 / 1, objectFit: "contain" }} className="w-100 p-1" src={this.state.product.images.length > 0 ? this.state.product.images[0].src : productImage}></LazyLoadImage>
                                            </div>

                                            <div className="product-img-small-card me-2">
                                                <LazyLoadImage style={{ aspectRatio: 1 / 1, objectFit: "contain" }} className="w-100 p-1" src={this.state.product.images.length > 0 ? this.state.product.images[0].src : productImage}></LazyLoadImage>
                                            </div>
                                            <div className="product-img-small-card me-2">
                                                <LazyLoadImage style={{ aspectRatio: 1 / 1, objectFit: "contain" }} className="w-100 p-1" src={this.state.product.images.length > 0 ? this.state.product.images[0].src : productImage}></LazyLoadImage>
                                            </div>
                                            <div className="product-img-small-card">
                                                <LazyLoadImage style={{ aspectRatio: 1 / 1, objectFit: "contain" }} className="w-100 p-1" src={this.state.product.images.length > 0 ? this.state.product.images[0].src : productImage}></LazyLoadImage>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>

                        </Container>
                        <Container className="px-0 px-md-5 bg-darks">
                            <div className="px-4">
                                <Nav tabs >
                                    <NavItem>
                                        <NavLink
                                            className={this.state.toggle === '1' ? "active" : ""}
                                            onClick={() => { this.setState({ toggle: "1" }) }}
                                        >
                                            Description
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.toggle === '2' ? "active" : ""}
                                            onClick={() => { this.setState({ toggle: "2" }) }}
                                        >
                                            Attributs complémentaires
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.toggle}>
                                    <TabPane tabId="1">
                                        <p>Cas number</p>
                                        <p>Code produit startlight</p>
                                        <p>Long description</p>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        ssqsqs
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Container>
                        <SpecialProducts />

                    </>
                    : <></>
                }
            </div>
        )
    }

}

export default withRouter(Product)