import React, { Component, useEffect, useState } from "react"

import { Container, Col, Row, FormGroup, Label, Input, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Error404 from "./404"
import productImage from '../statics/images/assets/product-image.jpg'
import PrimaryButton from "../components/primary-button.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import logo from '../statics/images/logos/wepredic.png'


const Login = (props) => {


    useEffect(() => {

    })


    return (
        <div className="pt-5">
            <Container className="py-4">
                <div className="w-100 text-center py-5">
                    <div className="w-50 mx-auto">
                        <LazyLoadImage width={60} height={"auto"} src={logo} />
                        <h2 className="part-title pb-0 pt-2 text-dark"><b>Your account for everything Wepredic</b></h2>
                    </div>
                </div>
                <Row>
                    <Col md={4}></Col>
                    <Col md={4}>
                        <Form>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">E-mail</Label>
                                <Input type="email" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="mb-0 small text-muted">Password</Label>
                                <Input type="password" />
                            </FormGroup>
                            <FormGroup className="d-flex flex-row justify-content-between">
                                <div>
                                    <Input type="checkbox" />
                                    <Label className="small text-muted">Keep me signed in</Label>
                                </div>
                                <a href="" className="text-dark small">Forgot password?</a>
                            </FormGroup>
                            
                            <FormGroup>
                                <PrimaryButton customClasses="w-100 mt-1" action={() => { }} content="Login" theme="1" />
                            </FormGroup>
                            <div className="w-100 text-center">
                                <p className="small text-grey">Not a member? <a className="text-dark" href="">Join us</a></p>
                            </div>
                        </Form>
                    </Col>
                </Row>



            </Container>
        </div>
    )
}

export default Login