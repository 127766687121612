import React from "react";
import { Container, Col, Row, Badge } from 'reactstrap'


export default function SpecialProducts(props) {


    return (
        <Container className="py-5">
            <h3 className="part-title py-4 text-dark text-end">Products. <span className="text-grey">Essentials that combine with [...].</span> </h3>


            <Row className="pt-3 pb-5">
                <Col lg={4} className="">
                    <div className="my-card h-100">
                        <div className="p-4 d-flex flex-column h-100">
                            <div>
                                <Badge className="my-badge text-uppercase bg-biopredic" color="" pill>Chemical product</Badge>
                                <p className="py-3 m-0"><b>CYP1A2 Substrat Phanecetin</b></p>
                                <p className="small text-muted pb-5">Primary hepatocytes are widely used as the gold-standard in-vitro liver model for biological, pharmacological and toxicological studies in drug discovery and development.</p>
                            </div>
                            <p className="small mt-auto mb-0 "><b>À partir de 44,60€ HT - <span className="text-grey">48,90€ TTC</span></b></p>

                        </div>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="my-card h-100">
                        <div className="p-4 d-flex flex-column h-100">
                            <div>
                                <Badge className="my-badge text-uppercase bg-starlight-dark" color="" pill>Service product</Badge>
                                <p className="py-3 m-0"><b>OATP1B1 Substrat - Esterone sulfate</b></p>
                                <p className="small text-muted pb-5">Primary hepatocytes are widely used as the gold-standard in-vitro liver model for biological, pharmacological and toxicological studies in drug discovery and development.</p>
                            </div>
                            <p className="small mt-auto mb-0 "><b>À partir de 44,60€ HT - <span className="text-grey">48,90€ TTC</span></b></p>

                        </div>

                    </div>
                </Col>
                <Col lg={4}>
                    <div className="my-card h-100">
                        <div className="p-4 d-flex flex-column h-100">
                            <div >
                                <Badge className="my-badge text-uppercase bg-eurosafe" color="" pill>Chemical product</Badge>
                                <p className="py-3 m-0"><b>CYP1A2 Substrat Phanecetin</b></p>
                                <p className="small text-muted pb-5">Primary hepatocytes are widely used as the gold-standard in-vitro liver model for.</p>
                            </div>
                            <p className="small mt-auto mb-0 "><b>À partir de 44,60€ HT - <span className="text-grey">48,90€ TTC</span></b></p>

                        </div>

                    </div>
                </Col>
            </Row>
        </Container>

    );
}